import { makeAutoObservable } from 'mobx'

class City {
  code?: string = ''
  areaCode?:string=''
  coordinates?: [number,number]
  constructor() {
    makeAutoObservable(this)
  }
}

const cityStore = new City()

export default cityStore
