import React, { useCallback,useMemo, useState, useRef } from 'react'
import AlarmModal, { OnAlarmClick, AlarmType } from '@/components/WarningModal';
import Panel from '@/components/Panel/index'
import style from './style.module.scss'
import cn from 'classnames'
import { GetParkAlarm } from '@/services/api'
import { useRequest } from 'ahooks'
import { Spin } from 'antd'
import { observer } from 'mobx-react'
import ImgSource from '@/assets/img/resource/baojing.png'
/**
 * 小象城泊报警管理
 */
 import Abutton from '@/components/Abutton'
 const iframeURL = "http://lz.dgjx0769.com/lz/#/UrbanIntegration/DeviceLongRangeMaintain/EquipmentAlarm";
export interface WarningProps {
  areaCode?: string;
  lg?: boolean;
  onOpenWorkbill: () => void;
  onAlarmFocus: (roadSectionId: string,parkingNo: string) => void;
}

const Warning: React.FC<WarningProps> = (props) => {
  const [alarmModal,setAlarmModal] = useState(false);
  const alarmRef = useRef<{
    onAlarmClick: OnAlarmClick
  }>(null);

  const { data = [], loading, error } = useRequest(useCallback(() => {
    return GetParkAlarm(props.areaCode)
  }, [props.areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [props.areaCode]
  })



  const showIframe = () => {
    setAlarmModal(false);
    props.onOpenWorkbill();
  }

  const showAlarm = (alarmType: number) => {
    setAlarmModal(true);
    alarmRef.current?.onAlarmClick(alarmType as AlarmType);
  }

  const AbuttonBox = useMemo(() => {
    return <Abutton iframeURL={iframeURL} text={''} />
  }, [])


  if (error && loading === false) {
    return (
      <Panel title="报警管理">
        <div className={style.fetch_container} style={{ height: props.lg ? 200 : 185 }}>
          请求失败
        </div>
      </Panel>
    )
  }
 
  return (
    <Panel title="报警管理"   >
    
      {
        loading ?
        <div className={style.fetch_container} style={{ height: props.lg ? 200 : 185 }}> 
          <Spin/>
        </div> :
        data &&
        <div className={`${style.container} ${props.lg ? style.lg : ''}`}>
          <div className={style.top}>
            {
              ['设备故障', '人员上报事件', '套牌车','僵尸车','敏感车','未知订单量'].map((item, index) => {
                const key = ['deviceFailure', 'inspectorEvent', 'fakePlate','zoomBieCar','sensitiveCar','irregularPark'] as (keyof API.ParkAlarmData)[]
                
                 const value =data[key[index]]||0        
                const isPlus = value > 99 
                return (
                  <div  onClick={()=>showAlarm(index+1)} className={cn(style.item, {
                    [style.red]: index === 0,
                    [style.yellow]: index === 1,
                    [style.green]: index === 2,
                    [style.green1]: index === 3,
                    [style.yellow1]: index === 4,
                    [style.red1]: index === 5
                  })} key={`warning_${index}`}>
                    <div className={cn(style.number, {
                      [style.plus]: isPlus
                    })}>
                      <div className={style.out}></div>
                      <div className={style.mid}></div>
                      <div className={style.inner}></div>
                      <div className={style.inner}></div>
                      <div className={style.inner}></div>
                      {value > 99 ? '99' : value}
                    </div>
                    <div className={style.label}>{item}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      }
      <AlarmModal
        areaCode={props.areaCode}
        ref={alarmRef}
        visible={alarmModal}
        onShowIframe={showIframe}
        onClose={() => setAlarmModal(false)}
        onAlarmFocus={props.onAlarmFocus}
      />
    </Panel>
  )
}

export default observer(Warning)