import React from 'react'
import style from './style.module.scss'

const ParkingAnimationIcon: React.FC = () => {
  return (
    <div className={style.animation_icon}>
      <div className={style.parking_icon}></div>
      <div className={style.shape1}></div>
      <div className={style.shape2}></div>
      <div className={style.base}></div>
    </div>
  )
}

export default ParkingAnimationIcon