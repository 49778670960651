import React, { useRef,useState } from 'react';
import { Modal } from 'antd';
import { GetStreetParkImage } from '@/services/api';
import styles from './index.module.scss';
import StreetHistoryModal from '@/components/StreetHistoryModal';
import IframeModal from '@/components/IframeModal';
const StreetViewModal:React.FC<{
  visible: boolean;
  onCancel: () => void;
  onShowPicture: (src: string, extra: object) => void;
  data: {
    berthId?: string;
    dayIncome?: number;
    arrearsIncome?: number;
    status?: number;
    berthNo?: string;
    orderId?: string;
    deviceCode?: string;
    orderCount?: number;
    plateNo?: string;
    driveInTime?: string;
    roadSectionName?: string;
    deviceName?: string;
    alarmInfoVos?: {
      alarmInfo: string;
    }[];
  }
}> = ({visible,onCancel,onShowPicture,data}) => {
  const[showiframeModal,setShowiframeModal]=useState(false)
  const staffprame={
    orderId:data.orderId
  }
  const showPicture =  async () => {
    try {
      const res = await GetStreetParkImage(data.orderId as string);
      if(res.code===200) {
        onCancel();
        onShowPicture(res.data.photoAddr,res.data);
      }
    }
    catch (error) {}
  }
  const openiframe=()=>{
    setShowiframeModal(true)
    
  }
  return (
    <Modal
      className={styles.street_view}
      width={260}
      footer={null}
      visible={visible}
      onCancel={onCancel}
      closeIcon={<img src="/img/icon_close_circle.png" alt="" className={styles.window_close} />}
      maskStyle={{background: 'transparent'}}
    >
       <div className={styles.street_view_info}>路段名称：{data.roadSectionName}</div>
      <div className={styles.street_view_info}>泊位号：{data.berthNo}</div>
      <div className={styles.street_view_info}>设备类型：{data.deviceName}</div>
      <div className={styles.street_view_info}>设备编号：{data.deviceCode}</div>
      <div className={styles.street_view_info}>今日频次：{data.orderCount}</div>
      {/* <div className={styles.street_view_info}>巡检员姓名：{data.orderCount}</div>
      <div className={styles.street_view_info}>电话：{data.orderCount}</div> */}
      <div className={styles.street_view_info}>今日收入：{data.dayIncome}</div>

      {
        data.status === 1 &&  <>
          <div className={styles.street_view_car}>
            <div>车辆牌照：{data.plateNo}</div>
            <img src="/img/icon_camera.png" alt="" onClick={showPicture} />
          </div>
          <div className={styles.street_view_info}>开始时间：{data.driveInTime}</div>
          <div className={styles.street_view_info}>车辆欠费金额：￥{data.arrearsIncome}</div>
          
        </>
        
      }
      <div className={styles.info_window_parklot}>
        <button  onClick={() => {
          openiframe()         
        }}
        >
          历史订单
        </button>

      </div>
      {
        data.alarmInfoVos && data.alarmInfoVos.length > 0 && <div className={styles.street_view_alarm}>
          <div>报警（{data?.alarmInfoVos?.length}）</div>
          {
            data?.alarmInfoVos?.map((item,index) => (
              <div key={index}>{index+1}.{item.alarmInfo}</div>
            ))
          }
        </div>
      }
      <IframeModal visible={showiframeModal} data={data}  onCancel={() => setShowiframeModal(false)} />
    </Modal>
    
  )
}

export default StreetViewModal;