import React, { useState, useCallback } from 'react';
import ImgSource from '@/assets/img/center/naxiqu@2x.png'
import styles from './index.module.scss';
  interface CenterViewProps {  
    sendIframeMessage: (params: {
      type: string;
      data: any;
    }) => void;
    
  }
  const CenterView: React.FC<CenterViewProps> = (props) => {
    return (
      <div className={`${styles.centerbg}${styles.hide}`}>
        {/* <div className={`${styles.center_box_img}`}>
         <img src={ImgSource} alt=""/>
        </div> */}
      </div>
    )
  }
  
  export default  CenterView