import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useRequest, useInterval } from 'ahooks';
import Panel from '@/components/Panel';
import CustomTable from '@/components/CustomTable';
import { Spin, Button, Input } from 'antd';
import { GetExceptioncCarData } from '@/services/api';
import styles from './index.module.scss';
import ZoneSelect from '@/components/ZoneSelect';
import ImgSource from '@/assets/img/jianguan/feifazhanyongchewei@2x.png'
import Abutton from '@/components/Abutton'
/** 转地址 */
const iframeURL = "http://lz.dgjx0769.com/lz/#/UrbanIntegration/EventManage/InspectionReport";
export interface ParkAbnormalTabelProps {
  areaCode?: string;
  showPicture: (src: string, extraData?: object) => void;
  
}

const $ = (selector: string) => document.querySelector(selector);

const ParkAbnormalTabel:React.FC<ParkAbnormalTabelProps> = ({areaCode,showPicture}) => {
  const [scrollInterval, setScrollInterval] = useState<number | null>(null);
  const [allowRefresh,setAllowRefresh] = useState(true);
  const [searchValue,setSearchValue] = useState<string>();

  const { data = [], refresh, loading,  error } = useRequest(useCallback(() => {
    return GetExceptioncCarData(areaCode,'15');
  },[areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [areaCode]
  })

// eslint-disable-next-line react-hooks/exhaustive-deps
let newdata=[{
  mobile: "13512365245",
  orderId: "0",
  time:'2021-09-08',
  plateNo: "京A123456",
  roadSectionName: "滨江路"
},
{
  mobile: "13512365245",
  orderId: "0",
  time:'2021-09-09',
  plateNo: "京A123456",
  roadSectionName: "滨江路"
},{
  mobile: "13512365245",
  orderId: "0",
  time:'2021-09-10',
  plateNo: "京A123456",
  roadSectionName: "滨江路"
},{
  mobile: "13512365245",
  orderId: "0",
  time:'2021-09-11',
  plateNo: "京A123456",
  roadSectionName: "滨江路"
}
]
  const tableData = useMemo(() => {
    if(!searchValue) return newdata;
    return newdata.filter((item,index) => item.plateNo.indexOf(searchValue) != -1);
  },[newdata,searchValue])

  const SearchInputMemo = useMemo(() => {
    return <Input.Search
      className={styles.search_input}
      onSearch={(value) => setSearchValue(value)}
      placeholder="搜索车牌"
      enterButton={<img src="/img/icon_search_white.png" alt=""/>}
    />
  }, [setSearchValue])

  const AbuttonBox = useMemo(() => {
    return <Abutton  iframeURL={iframeURL} text={''}/>
  }, [])

  const scrollContainer = useCallback(() => {
    const container = $('.parkabnormm-in-table .ant-table-body');
    if (container !== null) {
      const { clientHeight, scrollHeight, scrollTop } = container;
      let height = scrollTop + 1;
      const top = scrollHeight - clientHeight - height;
      if (top <= 0) {
        height = 0
      }

      if (scrollHeight <= clientHeight) {
        setScrollInterval(null)
      }
      
      container.scrollTo(0,height)
    }
  }, [])

  const stopTick = useCallback(() => {
    setAllowRefresh(false);
    setScrollInterval(null)
  }, [])
  
  const startTick = useCallback(() => {
    setAllowRefresh(true);
    setScrollInterval(100)
  }, [])

  useInterval(() => {
    scrollContainer()
  }, scrollInterval)

  useInterval(() => {
    //refresh();
  },allowRefresh ? 76000 : null)

  useEffect(() => {
    const container = $('.parkabnormm-in-table .ant-table-body')
    if (container) {
      const { clientHeight, scrollHeight } = container;
      const isOver = scrollHeight > clientHeight
      if (isOver) {
        setScrollInterval(100)
      }
    }
  }, [tableData])

  if (loading) {
    return (
      <Panel
        title="敏感车辆"

      >
        <div className="fetch_container" style={{ height: 200 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if (error) {
    return (
      <Panel title="敏感车辆" >
        <div className="fetch_container" style={{ height: 200 }}>
          请求错误
        </div>
      </Panel>
    )
  }

  return (
    <Panel
      title="敏感车辆"
 
    >
      <div onMouseEnter={stopTick} onMouseLeave={startTick}>
      
        <CustomTable
          className="parkabnormm-in-table"
          dataSource={data}
          rowKey={record => `${record.mobile}`}
          style={{height: '196px'}}
          columns={[          
            { title: '车牌号', width: 80, dataIndex: 'plateNo' },
            { title: '车主信息', width: 90, dataIndex: 'mobile',render:(text,record,index)=>`${record.mobile?record.mobile.slice(0,7)+`****`:''}`},
            { title: '路段', width: 90, dataIndex: 'roadSectionName', className: 'text-ellipsis' },
          
            { title: '入场时间', width: 80, dataIndex: 'driveInTime',}
          ]}
          scroll={{ y: 156 }}
        />
      </div>
    </Panel>
  )
}
export default ParkAbnormalTabel;