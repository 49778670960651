/// <reference path="./API.d.ts" />
import request from "./request2";

export async function getverifyCode() {
  return request.get("fss-auth/p-api/v1/verify-code/data");
}
//登录

export async function login(params: {
  username?: string;
  password?: string;
  verifyCode?: object;
}) {
  return request.post("fss-auth/p-api/v1/admin/login", params);
}

// 根据id获取用户权限树
export async function getUserPermission(token: string) {
  // return request.get(`ims-operating-provider/p-api/v1/sys-user/get/${id}`)
  console.log(token)
  return request.get(
    // "ims-operating-provider/p-api/v1/sys-menu-personal/get-menu-tree",
    "tvs-traffic/p-api/v1/admin/get/user/menu/tree",
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

// 根据id获取用户角色
// export async function getUserRole(token: string) {
//   // return request.get(`ims-operating-provider/p-api/v1/sys-user/get/${id}`)
//   return request.get(
//     "ims-operating-provider/p-api/v1/sys-user/get-current-user-info",
//     {
//       headers: {
//         Authorization: token,
//       },
//     }
//   );
// }
