import React from 'react';
import styles from './index.module.scss';

const Pillar:React.FC<{
  level: '1' | '2' | '3'
}> = ({level}) => {
  return (
    <div className={`${styles.pillar} ${styles[`pillar_level_${level}`]}`}>
      <div className={`${styles.spred} ${styles.spred_1}`}></div>
      <div className={`${styles.spred} ${styles.spred_2}`}></div>
      <div className={`${styles.spred} ${styles.spred_3}`}></div>
    </div>
  )
}

export default Pillar;