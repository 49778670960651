import React from 'react'
import style from './style.module.scss'
import Convert from '@/components/Panel/Convert'
import ParkingLot from '@/components/Panel/ParkingLot'
import ParkingDensity from '@/components/Panel/ParkingDensity';
import ParkingTrend from '@/components/Panel/ParkingTrend';
import ParkingCritical from '@/components/Panel/ParkingCritical';
import ParkRoad from '@/components/Panel/ParkRoad';
import ParkingShort from '@/components/Panel/ParkingShort';
import InspectorTable from '@/components/Panel/InspectorTable';
import ParkInTable from '@/components/Panel/ParkInTable';
import CarTable from '@/components/Panel/CarTable';
import KeyCarTable from '@/components/Panel/KeyCarTable';
import BusyParking from '@/components/Panel/BusyParking';
import ParkingUsage from '@/components/Panel/ParkingUsage';
import { observer } from 'mobx-react'
import cityStore from '@/store/city'
import ParkikngResource from '@/components/Panel/ParkingResources'
import ParkingTurnoverTable from '@/components/Panel/luzhou/ParkingTurnoverTable/ParkingTurnoverTable'
import ParkingDeviceCount from '@/components/Panel/luzhou/device/ParkingDeviceCount'
import Warning from '@/components/Panel/luzhou/Warning'
import ParkingIncome from '@/components/Panel/luzhou/ParkingIncome'
import ParkingDailyIncomeProps from '@/components/Panel/luzhou/ParkingDailyIncome'
import ParkingMonthlyIncome from '@/components/Panel/luzhou/ParkingMonthlyIncome'
import ParkingRechargeIncome from '@/components/Panel/luzhou/ParkingRechargeIncome'
import ParkLotRanking from '@/components/Panel/luzhou/ParkLotRanking'
import ParkiNewLotRanking from '@/components/Panel/luzhou/ParkiNewLotRanking'
import ServiceIncomeRanking from '@/components/Panel/luzhou/ServiceIncomeRanking'
import ParkAbnormalTabel from '@/components/Panel/luzhou/ParkAbnormalTabel'
import ParkingBlindSpotLz from '@/components/Panel/luzhou/ParkBlindSpotlz'
import ParkingDemand from '@/components/Panel/luzhou/ParkingDemand'
import ParkingFeedback from '@/components/Panel/luzhou/ParkingFeedback'
import CenterView from '@/components/centerView/'

interface LuBlocksProps {
  areaCode?: string;
  type: number;
  sendIframeMessage: (params: {
    type: string;
    data: any;
  }) => void;
  onOpenWorkbill: () => void;
  showPicture: (src: string, extraData?: object) => void;
  onAlarmFocus: (roadSectionId: string,parkingNo: string) => void;
  onTabelItemUrlChange:(src: string)=>void;
}

const LuBlocks: React.FC<LuBlocksProps> = (props) => {
  const onDeviceTypeChange = (val: number) => {
    props.sendIframeMessage({
      type:'deviceChange',
      data:val
    })

  }

  if (props.type === 0) {
    return (
      <div>
        <div className={`${style.block_container} ${style.left}`}>
          <div className={style.item}>
          
            <ParkikngResource areaCode={cityStore.areaCode}/>
          </div>
          <div className={style.item}>
          
            <ParkingTurnoverTable  areaCode={cityStore.areaCode} sendIframeMessage={props.sendIframeMessage} onOpenWorkbill={props.onOpenWorkbill} onAlarmFocus={props.onAlarmFocus}/>
          </div>
          <div className={style.item}>
         
            <ParkingDeviceCount  areaCode={cityStore.areaCode} onDeviceTypeChange={onDeviceTypeChange}/>
          </div>
        </div>
      
           <div className={`${style.block_container} ${style.center}` } >
             <CenterView sendIframeMessage={props.sendIframeMessage} ></CenterView>
             </div>
    
        <div className={`${style.block_container} ${style.right}`}>
          <div className={style.item}>
            <Warning areaCode={cityStore.areaCode} onOpenWorkbill={props.onOpenWorkbill} onAlarmFocus={props.onAlarmFocus} />
          </div>
          <div className={style.item}>
            <ParkingLot areaCode={cityStore.areaCode} onOpenWorkbill={props.onOpenWorkbill} onAlarmFocus={props.onAlarmFocus}/>
          </div>
          <div className={style.item}>
            <Convert areaCode={cityStore.areaCode}/>
          </div>
        </div>
      </div>
    )
  }

  
  if (props.type === 1) {
    return (
      <div>
        <div className={`${style.block_container} ${style.left}`}>
          <div className={style.item}>
          
            <ParkingIncome areaCode ={cityStore.areaCode}/>
          </div>
          <div className={style.item}>
           
            <ParkingDailyIncomeProps areaCode={cityStore.areaCode} />
          </div>
          <div className={style.item}>
           
            <ParkingMonthlyIncome areaCode={cityStore.areaCode} />
          </div>
        </div>
        <div className={`${style.block_container} ${style.right}`}>
        <div className={style.item}>
           <ParkingRechargeIncome areaCode={cityStore.areaCode} />
         </div>
         <div className={style.item}>
            <ParkLotRanking areaCode={cityStore.areaCode} />
          </div>
          <div className={style.item}>
          
          <ParkingTurnoverTable  areaCode={cityStore.areaCode} sendIframeMessage={props.sendIframeMessage} onOpenWorkbill={props.onOpenWorkbill} onAlarmFocus={props.onAlarmFocus}/>
        </div>
          
          {/* <div className={style.item}>
            <ServiceIncomeRanking areaCode={cityStore.areaCode}/>
          </div> */}
          
        </div>


      </div>
    )
  }
  if (props.type === 2) {
    return (
      <div>
        <div className={`${style.block_container} ${style.left}`}>
          <div className={style.item}>
            <Warning areaCode={cityStore.areaCode} lg onOpenWorkbill={props.onOpenWorkbill} onAlarmFocus={props.onAlarmFocus} />
          </div>
          <div className={style.item}>
            <InspectorTable areaCode={cityStore.areaCode} sendIframeMessage={props.sendIframeMessage} />
          </div>
          <div className={style.item}>
            <ParkInTable areaCode={cityStore.areaCode} showPicture={props.showPicture}   onTabelItemUrlChange={props.onTabelItemUrlChange}/>
          </div>
        </div>
        <div className={`${style.block_container} ${style.right}`}>
          <div className={style.item}>
            <CarTable areaCode={cityStore.areaCode} showPicture={props.showPicture}/>
          </div>
          <div className={style.item}>
            { <ParkAbnormalTabel areaCode={cityStore.areaCode} showPicture={props.showPicture}/> }
          </div>
          
          <div className={style.item}>
            <KeyCarTable areaCode={cityStore.areaCode}/>
          </div>
        </div>
      </div>
    )
  }

  if (props.type === 4) {
    return (
      <div>
        <div className={`${style.block_container} ${style.left}`}>
          <div className={style.item}>
            <ParkingDensity areaCode={cityStore.areaCode} />
          </div>
          <div className={style.item}>
            <ParkingTrend areaCode={cityStore.areaCode} />
          </div>
        </div>
        <div className={`${style.block_container} ${style.right}`}>
          <div className={style.item}>
            <ParkingCritical areaCode={cityStore.areaCode} />
          </div>
          <div className={style.item}>
            <ParkRoad areaCode={cityStore.areaCode} />
          </div>
          <div className={style.item}>
            <ParkingShort areaCode={cityStore.areaCode} />
          </div>
        </div>
      </div>
    )
  }



  if (props.type === 3) {
    return (
      <div>
        <div className={`${style.block_container} ${style.left}`}>
          <div className={style.item}>
         
            <ParkingBlindSpotLz areaCode={cityStore.areaCode} />
          </div>
          <div className={style.item}>
          
           {<ParkingDemand areaCode={cityStore.areaCode}/>}
          </div>
          <div className={style.item}>
            {<ParkingFeedback areaCode={cityStore.areaCode} />}
          </div>
        </div>
        <div className={`${style.block_container} ${style.right}`}>
        <div className={`${style.item} ${style.item_box2}`}>
            <BusyParking areaCode={cityStore.areaCode} />
          </div>
          <div className={style.item}>
            <ParkingUsage areaCode={cityStore.areaCode}/>
          </div>
        </div>
      </div>
    )
  }



  return null
}

export default observer(LuBlocks)
