import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { CaretDownOutlined } from '@ant-design/icons';
import styles from  './index.module.scss'

const CustomSelect:React.FC<SelectProps<any>> = (props) => {
  
  return (
    <Select
      {...props}
      className={`${styles.custom_select} ${props.className}`}
      suffixIcon={<CaretDownOutlined className={styles.custom_select_arrow} />}
      bordered={false}
      dropdownClassName={`${styles.custom_select_dropdown} ${props.dropdownClassName}`}
    />
  )
}

export default CustomSelect;