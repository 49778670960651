import React, { useState, useCallback,useEffect } from 'react';
import AlarmModal from '@/components/AlarmModal';
import { useRequest, useInterval } from 'ahooks';
import {
  GetParkResourceStatic,
  GetincomesourceStatic,
  GetSuperviseStatic,
  GetSuperviseTwoStatic,
  GetGrovernStatic

  
} from '@/services/api';
import { observer } from 'mobx-react';
import { useCountUp } from 'react-countup';
import styles from './index.module.scss';
import { number } from 'echarts';

const topOffsetStyle = {
  top: '100px'
}

const formatNumber = (val: number = 0) => {
  return (val/10000).toFixed(0) + '万'
}

const countUpProps = { start: 0, end: 0, duration: 2, };

const DataPreview:React.FC<{
  onAlarmFocus: (roadSectionId: string,parkingNo: string) => void;
  onOpenWorkbill: () => void;
  menu: number;
  areaCode?: string;
}> = ({onAlarmFocus,onOpenWorkbill,menu, areaCode}) => {
  const [alarmModal,setAlarmModal] = useState(false);
  const [manual,setmanual] = useState(false);
  const [manual1,setmanual1] = useState(false);
  const [manual2,setmanual2] = useState(false);
  const [manual3,setmanual3] = useState(false);
  const { countUp: countUpOwnCount, update: updateOwnCount } = useCountUp(countUpProps);
  const { countUp: countUpTotalCount, update: updateTotalCount } = useCountUp(countUpProps);
  const { countUp: countUpEnterParkCount, update: updateEnterParkCount } = useCountUp(countUpProps);
  const { countUp: countUpWaitEnterParkCount, update: updateWaitEnterParkCount } = useCountUp(countUpProps);
  const { countUp: countUpTotalRealIncome, update: updataTotalRealIncome } = useCountUp(countUpProps);
  const { countUp: countUpTotalShouldIncome, update: updateTotalShouldIncome } = useCountUp(countUpProps);
  const { countUp: countUpRegisterCount, update: updateRegisterCount } = useCountUp(countUpProps);
  const { countUp: countUpUserCount, update: updateUserCount } = useCountUp(countUpProps);
  const { countUp: countUpPlateCount, update: updatePlateCount } = useCountUp(countUpProps);
  const { countUp: countUpAlarmCount, update: updateAlarmCount } = useCountUp(countUpProps);
  const {countUp: countUpExceptionCount,update:updateExceptionCount} =useCountUp(countUpProps);
  const {countUp: countUpHandleCount,update:updateHandleCount}=useCountUp(countUpProps);
  const { countUp: countUpParkUseRatio, update: updateParkUseRatio } = useCountUp(countUpProps);
  const { countUp: countUpParkTurnRatio, update: updateParkTurnRatio } = useCountUp(countUpProps);
  const { countUp: countUpParkAvgTime, update: updateParkAvgTime } = useCountUp(countUpProps);
  const { countUp: countUpUseRatio, update: updateUseRatio } = useCountUp(countUpProps);
  const { countUp: countUpAvgParkingTime, update: updateAvgParkingTime } = useCountUp(countUpProps);
  const { countUp: countUpDifficultParkCount, update: updateDifficultParkCount } = useCountUp(countUpProps);
  const { countUp: countUpBlindCount, update: updateBlindCount } = useCountUp(countUpProps);
  const { countUp: countUpParkingCount, update: updateParkingCount } = useCountUp(countUpProps);
  const { countUp: countUpParkFocusRatio, update: updateParkFocusRatio } = useCountUp(countUpProps);
  const { countUp: countUpParkGapCount, update: updateParkGapCount } = useCountUp(countUpProps);
  const { countUp: countUpCarParkCount, update: updateCarParkCount } = useCountUp(countUpProps);
  const { countUp: countUpInspectorCount, update: updateInspectorCount } = useCountUp(countUpProps);
  const { countUp: countUpMonitorAddUserCount, update: updateMonitorAddUserCount } = useCountUp(countUpProps);
  const { countUp: countUpMonitorUserCount, update: updateMonitorUserCount } = useCountUp(countUpProps);
  const { countUp: countUpMonitorAddPlateCount, update: updateMonitorAddPlateCount } = useCountUp(countUpProps);
  const { countUp: countUpMonitorPlateCount, update: updateMonitorPlateCount } = useCountUp(countUpProps);

  const { countUp: parkCount, update: updateParkCount } = useCountUp(countUpProps);
  
  const { countUp: roadCount, update: updateRoadCount } = useCountUp(countUpProps);
  const { countUp: orderCount, update: updateOrderCount } = useCountUp(countUpProps);

  const { countUp: incomeTotal, update: updateIncomeTotal } = useCountUp(countUpProps);
  const { countUp: ownIncomeTotal, update: updateOwnIncomeTotal} = useCountUp(countUpProps);
  const { countUp: ParkIncomeTotal, update: updateParkIncomeTotal} = useCountUp(countUpProps);
  const { countUp: RoadIncomeTotal, update: updateRoadIncomeTotal} = useCountUp(countUpProps);
  const { countUp: joinIncomeTotal, update: updateJoinIncomeTotal } = useCountUp(countUpProps);
  const { countUp: addIncomeTotal, update: updateAddIncomeTotal } = useCountUp(countUpProps);
  const [ turnover, setupdateTurnover] =useState(0);
  const [ getTurnover, setTurnover ] = useState('0');
  // const { countUp: ownIncomeTotal, update: updateOwnIncomeTotal} = useCountUp(countUpProps);
  // const { countUp: joinIncomeTotal, update: updateJoinIncomeTotal } = useCountUp(countUpProps);
  // const { countUp: addIncomeTotal, update: updateAddIncomeTotal } = useCountUp(countUpProps);

  //menu!=0&&setmanual(true)

 const  {data:parkData,run:GetParkResourceStaticData } =  useRequest(useCallback(()=>{
   return GetParkResourceStatic(areaCode)
  },[areaCode]),{
    formatResult:res =>res.data,
    onSuccess:(data:API.GetParkResourceStatic)=>{
      updateOwnCount(data.ownCount||0);
      updateTotalCount(data.totalCount||0);
      updateEnterParkCount(data.enterParkCount||0);
      updateParkCount(data.parkCount||0);
      updateRoadCount(data.roadCount||0);
      setupdateTurnover(data.turnover ||0);
      updateOrderCount(data.orderCount)
      
      updateWaitEnterParkCount(data.waitEnterParkCount||0);
      updataTotalRealIncome(data.totalRealIncome||0);

      updateTotalShouldIncome(data.totalShouldIncome||0);
      updateUserCount(data.userCount||0);

      updateRegisterCount(data.registerCount||0);

      updateExceptionCount(data.exceptionCount||0);
      updateHandleCount(data.handleCount||0);
      updatePlateCount(data.plateCount||0);
    },
   
    refreshDeps: [areaCode]
  })
  //停车经营 总数据
  const {data:incomeData,run:GetincomeData } =useRequest(useCallback(()=>{
    return GetincomesourceStatic(areaCode,'510500')
   },[areaCode]),{
     formatResult:res =>res.data,
     onSuccess:(data)=>{
       
       updateIncomeTotal(data.incomeTotal||0);
       updateAddIncomeTotal(data.addIncomeTotal||0);
       updateJoinIncomeTotal(data.joinIncomeTotal||0);
       updateOwnIncomeTotal(data.ownIncomeTotal||0);
       updateParkIncomeTotal(data.parkIncome||0);
       updateRoadIncomeTotal(data.roadIncome||0);

     },

     refreshDeps: [areaCode]
   })

//停车监管 总数据
   const {data:superviseData,run:GetsuperviseData } =useRequest(useCallback(()=>{
    return GetSuperviseStatic(areaCode)
   },[areaCode]),{
     formatResult:res =>res.data,
     onSuccess:(data)=>{
       updateInspectorCount(data.inspectorCount||0);
       updateMonitorAddUserCount(data.addUserCount||0);
       updateMonitorAddPlateCount(data.addCarCount||0);
       updateMonitorPlateCount(data.plateCount||0);
       updateMonitorUserCount(data.userCount||0);
     },
     refreshDeps: [areaCode]
   })
   const {data:supervise2Data,run:Getsupervise2Data } =useRequest(useCallback(()=>{
    return GetSuperviseTwoStatic(areaCode)
   },[areaCode]),{
     formatResult:res =>res.data,
     onSuccess:(data)=>{
      console.log(data)
      updateUseRatio(data?.turnover||0);
      setTurnover(data?.turnover||0)

     },

     refreshDeps: [areaCode]
   })
//停车难治理 总数据
const {data:grovernData,run:GetGrovernData } =useRequest(useCallback(()=>{
  return GetGrovernStatic(areaCode)
 },[areaCode]),{
   formatResult:res =>res.data,
   onSuccess:(data)=>{
    updateParkFocusRatio(data.parkingBlindSpotNo||0);
    updateParkGapCount(data.carCount||0)
    updateCarParkCount(data.parkingDifficulty||0)
   },
   refreshDeps: [areaCode]
 })


  const showIframe = () => {
    setAlarmModal(false);
    onOpenWorkbill();
  }

 
  useInterval(() => {
   // getSummaryTrafficData();
  GetParkResourceStaticData();
  GetincomeData()
  GetsuperviseData()
  Getsupervise2Data()
  GetGrovernData()
    
  },180000)


  return (
    <div className={`${styles.data_box} ${styles.data_box_sm}`} style={menu === 0 || menu === 1 ? {} : topOffsetStyle}>
      <div className={`${styles.data_item} ${menu === 0 ? '' : 'hide'}`}>
        <p>{roadCount>10000?formatNumber(Number(roadCount)):roadCount}/{parkCount>10000?formatNumber(Number(parkCount)):parkCount}</p>
      
       <span >
      (道路泊位数量/停车场数量)
       </span>
       <i></i>
      </div>
      <div className={`${styles.data_item} ${menu === 0 ? '' : 'hide'}`}>
        <p>{countUpEnterParkCount>10000?formatNumber(Number(countUpEnterParkCount)):countUpEnterParkCount}/{countUpWaitEnterParkCount>10000?formatNumber(Number(countUpWaitEnterParkCount)):countUpWaitEnterParkCount}</p>
       
        <span>
           (接入停车场数量/待接入停车场数量) 
           </span>
           <i></i>
      </div>
      <div className={`${styles.data_item} ${menu === 0 ? '' : 'hide'} ${styles.data_box_count}` } >
        {/* <p>{countUpTotalRealIncome}/{formatNumber(Number(countUpTotalShouldIncome))}</p> */}
        <p>{countUpTotalRealIncome}</p>
        <span> (今日应收) </span>
      </div>

      <div className={`${styles.data_item} ${menu === 0 ? '' : 'hide'} ${styles.data_item_size}`}>
      
        <p>{orderCount>10000?formatNumber(Number(orderCount)):orderCount}/{turnover==0?'0.0':turnover.toString().slice(0,3) }</p> 
        {/* <p>132万/98万</p>  */}
        <span > 
           (停车次数/平均周转率)
            </span>
            <em></em>
      </div>
      <div className={`${styles.data_item} ${menu === 0 ? '' : 'hide'} ${styles.data_item_size}`}>
      
        <p>{countUpRegisterCount>10000?formatNumber(Number(countUpRegisterCount)):countUpRegisterCount}/{countUpPlateCount>10000?formatNumber(Number(countUpPlateCount)):countUpPlateCount}</p>
        {/* <p>132万/98万</p> */}
        <span > 
         
           (注册用户数/车主数)
 
            </span>
            <em></em>
      </div>
      {/* <div className={`${styles.data_item}  ${styles.warn_item} ${menu === 0 ? '' : 'hide'}` }>
       
        <p>{countUpHandleCount}/{countUpExceptionCount}</p>
        {countUpExceptionCount>0
         ?<span onClick={showIframe}> (待处理异常/异常总数数)</span>
         :<span > (待处理异常/异常总数数)</span>
        }
        <em></em>
      </div> */}
      <div className={`${styles.data_item} ${menu === 1 ? '' : 'hide'}`}>
        <p>{incomeTotal} 元</p>
        <span> 
           收入总额
            </span>
      </div>
      <div className={`${styles.data_item} ${menu === 1 ? '' : 'hide'}`}>
        <p>{RoadIncomeTotal} 元</p>
        <span>道路停车收入 </span>
      </div>
      <div className={`${styles.data_item} ${menu === 1 ? '' : 'hide'}`}>
        <p>{ParkIncomeTotal} 元</p>
        <span>自有停车场收入 </span>
      </div>
      <div className={`${styles.data_item} ${menu === 1 ? '' : 'hide'}`}>
        <p>{joinIncomeTotal} 元</p>
       <span>接入车位总收入</span>
      </div>
      <div className={`${styles.data_item} ${menu === 1 ? '' : 'hide'}`}>
        <p>{addIncomeTotal} 元</p>
       <span> 增值业务总收入</span>
      </div>
      {/* <div className={`${styles.data_item}  ${styles.warn_item} ${menu === 1 ? '' : 'hide'}` }>
        <p>{0}</p>
        <span> 
          
        欠费总金额 </span>
      </div> */}
      

      <div className={`${styles.data_item} ${menu === 3 ? '' : 'hide'}`} style={{width: '33%'}}>
        <p>{countUpParkFocusRatio}</p>
        <span>停车盲点数</span>
      </div>
      <div className={`${styles.data_item} ${menu === 3 ? '' : 'hide'}`} style={{width: '33%'}}>
        <p>{countUpParkGapCount}</p>
        <span>汽车保有量</span>
      </div>
      <div className={`${styles.data_item} ${menu === 3 ? '' : 'hide'}`} style={{width: '33%'}}>
        <p>{countUpCarParkCount}</p>
        <span>停车难点数</span>
      </div>


      <div className={`${styles.data_item} ${menu === 2 ? '' : 'hide'}`}>
        <p>{countUpInspectorCount}</p>
        <span>在岗巡检员</span>
      </div>
      <div className={`${styles.data_item} ${menu === 2 ? '' : 'hide'}`}>
        <p>{countUpMonitorAddUserCount}/{countUpMonitorUserCount}</p>
        <span>服务用户</span>
      </div>
      <div className={`${styles.data_item} ${menu === 2 ? '' : 'hide'}`}>
        <p>{countUpMonitorAddPlateCount}/{countUpMonitorPlateCount}</p>
        <span>服务车辆</span>
      </div>
      <div className={`${styles.data_item} ${menu === 2 ? '' : 'hide'}`}>
        <p>{getTurnover=='0'?'0.0':getTurnover.toString().slice(0,3)}</p>
        <span>周转率</span>
      </div>
      {/* <div className={`${styles.data_item}  ${styles.warn_item} ${menu === 2 ? '' : 'hide'}` }>
        <p>{0}</p>
        <span> 
          
        设备报警 </span>
      </div> */}

      <div className={`${styles.data_item} ${menu === 4 ? '' : 'hide'}`}>
        <p>{countUpUseRatio}</p>
        车位利用率
      </div>
      <div className={`${styles.data_item} ${menu === 4 ? '' : 'hide'}`}>
        <p>{countUpAvgParkingTime}h</p>
        平均停放时长
      </div>
      <div className={`${styles.data_item} ${menu === 4 ? '' : 'hide'}`}>
        <p>{countUpDifficultParkCount}</p>
        停车难街道或停车场
      </div>
      <div className={`${styles.data_item} ${menu === 4 ? '' : 'hide'}`}>
        <p>{countUpBlindCount}</p>
        停车盲点
      </div>
     
     
    </div>
  )

}

export default observer(DataPreview);