import React, { useState, ReactNode } from 'react';
import { useThrottleFn } from 'ahooks';
import { ConfigProvider, DatePicker, Slider } from 'antd';
import Moment, { Moment as MomentType } from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { CaretDownOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
const marks:{
  [key: number]: ReactNode;
} = {};
for(let i = 0; i < 25; i ++) {
  marks[i*60] = <span style={{color: '#fff'}}>{i}</span>
}

const getMinutes = () => {
  return Moment().hours() * 60 + Moment().minutes();
}

const defaultMinute = getMinutes();

const defaultDate = Moment().hours(0).minutes(0);

const Timeline:React.FC<{
  onChange: (val: {
    date: string;
    time: string;
  }) => void;
  className?: string;
}> = ({onChange,className}) => {
  const [date,setDate] = useState(defaultDate);
  const [minute,setMinute] = useState(defaultMinute);
  const disabledDate = (currentDate: MomentType) => {
    return currentDate.format('yyyy-MM-DD') < '2019-01-01' || currentDate > Moment();
  }

  const callbackDateTime = (date: MomentType,minute: number) => {
    const newDate = date.clone();
    const dateTime = newDate.add(minute,'m');
    onChange({
      date: dateTime.format('yyyy-MM-DD'),
      time: dateTime.format('HH:mm')
    }); 
  }

  const pickerChange = (value: MomentType) => {
    setDate(value);
    callbackDateTime(value,minute);
  }

  const { run: sliderChange} = useThrottleFn((value: number) => {
    
    if(date.format('yyyy-MM-DD') == Moment().format('yyyy-MM-DD')) {
      const currentMinutes = getMinutes();
      if(value > currentMinutes) {
        setMinute(currentMinutes);
      } else {
        setMinute(value);
      }
    }else {
      setMinute(value);
    }
  }, { wait: 100 })

  

  return (
    <div className={`${styles.timeline} ${className}`}>
      <DatePicker
        value={date}
        onChange={val => pickerChange(val as MomentType)}
        disabledDate={disabledDate}
        allowClear={false}
        suffixIcon={<CaretDownOutlined />}
        locale={locale}
      />
      <Slider 
        value={minute}
        onChange={sliderChange}
        onAfterChange={(val: number) => callbackDateTime(date,val)}
        max={1439}
        step={1}
        marks={marks}
        tipFormatter={null}
      />
    </div>
  )
}

export default Timeline;