import React, { useState } from 'react'
import style from './style.module.scss'
import ImgSource from '@/assets/img/circles/source.png'
import ImgC1 from '@/assets/img/circles/c1.png'
import ImgC2 from '@/assets/img/circles/c2.png'
import ImgC3 from '@/assets/img/circles/c3.png'
import ImgC4 from '@/assets/img/circles/c4.png'
import ImgLight from '@/assets/img/circles/light.png'
import ImgLightUp from '@/assets/img/circles/light_up.png'

interface BallsProps {
  data: {
    label: string
    value: number
    id: string
  }[]
  center?: JSX.Element | string
  onChange?: (id: string) => void
}



const Balls: React.FC<BallsProps> = (props) => {
  const [activeIndex, changeActive] = useState(0)

  return (
    <div>
      <div className={style.ball_container}>
        {
          props.data.map((item, index) => {
            const eleIndex = (index + (6 - activeIndex)) % 6
            return (
              <div
                onClick={() => {
                  changeActive(index)
                  props.onChange?.(props.data[index].id)
                }}
                className={`${style.ball} index_${eleIndex}`}
                key={`ball_${index}`}>
                <div className={style.value}>{ item.value }</div>
                <div className={style.label}>{ item.label }</div>
              </div>
            )
          })
        }
        <div className={style.center_ball}>
          <div className={style.number}>{ props.center }</div>
          <div className={style.label}>平均停车时长</div>
        </div>
      </div>
      <div className={style.bottom}>
        <div className={style.source}>
          <img src={ImgSource} alt=""/>
        </div>
        <div className={style.light_up}>
          <img src={ImgLightUp} alt=""/>
        </div>
        <div className={style.circles}>
          <div className={style.circle_img}>
            <img src={ImgC1} alt=""/>
          </div>
          <div className={style.circle_img}>
            <img src={ImgC2} alt=""/>
          </div>
          <div className={style.circle_img}>
            <img src={ImgC3} alt=""/>
          </div>
          <div className={style.circle_img}>
            <img src={ImgC4} alt=""/>
          </div>
          <div className={style.light}>
            <img src={ImgLight} alt=""/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Balls