/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useRequest } from "ahooks";
import TopTale from "@/components/topTable";
import { Modal } from "antd";
import styles from "./index.module.scss";
import { Image, Spin, Pagination } from "antd";
import { GetstaffInfo, GetStreetParkList, GetreportInfo } from "@/services/api";
import Loading from "../Loading";

const AlarmModal: React.FC<{
  visible?: boolean;
  onCancel?: () => void;
  src?: string;
  data?: {
    berthId?: string;
    berthNo?: string;
    plateNo?: string;
    orderId?: string;
    programType?: number;
    programUrls?: Array<string>;
    eventManageId?: string;
    queryTime?: string;
    pageNum?: string;
    photo?: Array<string>;
  };
  typeModal?: string;
}> = forwardRef(({ data, visible, onCancel, src, typeModal }, ref) => {
  const [pageNum, setPageNum] = useState("1");
  const [pagetotal, setPagetotal] = useState(0);
  const programType = data?.programType || 0;
  const programUrlsdata = data?.programUrls || [];
  const id = data?.eventManageId || "";
  const parklotphonelist = data?.photo || [];

  const iframesrc = src || "";

  // console.log(data?.photo)
  // const { data:workorderData=[],run:list} = useRequest(useCallback(() => {
  //   return GetstaffInfo({
  //     eventManageId:id,
  //     pageNum,
  //     pageSize:''
  //   })},[pageNum,id]), {
  //     manual:true,
  //    formatResult: res => res.data.list
  // })
  const { data: workorderData = [], run: reportlist } = useRequest(
    useCallback(() => {
      return GetreportInfo({
        pageNum,
        pageSize: "10",
      });
    }, [pageNum, id]),
    {
      manual: true,
      formatResult: (res) => {
        setPagetotal(res.data.total);
        return res.data.list;
      },
    }
  );

  const { data: StreetParkData = [], run: StreetParklist, loading } = useRequest(
    useCallback(() => {
      return GetStreetParkList({
        berthId: data?.berthId,
        berthNo: data?.berthNo,
        plateNo: data?.plateNo,
        pageNum,
        pageSize: "15",
      });
    }, [data, data?.berthId, data?.berthNo, data?.plateNo, pageNum]),
    {
      manual: true,
      formatResult: (res) => {
        setPagetotal(res.data.total);
        return res.data.list;
      },
    }
  );
  useEffect(() => {
    if (data?.eventManageId) {
      reportlist();
    }
    if (data?.berthId) {
      StreetParklist();
    }
  }, [data, StreetParklist]);
  useImperativeHandle(ref, () => ({}));
  // if(data?.eventManageId){
  //   getworkorderList()
  // }
  // const programurl=data.programUrls.length>0?data.programUrls:[]
  const Typename = (val: number) => {
    return { 1: "车辆违停", 2: "套牌车", 3: "突发公共安全事件", 4: "设备故障", 5: "拥堵事件", 6: "缴费排队", 7: "不规范停车", 8: "其他问题" }[val];
  };
  const onChange = (page: any, pageSize: any) => {
    setPageNum(page);
    setTimeout(() => {
      data?.orderId && StreetParklist();
      data?.eventManageId && reportlist();
    }, 300);
  };

  //console.log(StreetParkData)
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      className={styles.alarm_modal}
      width={1350}
      footer={null}
      closeIcon={<img src="/img/icon_close.png" alt="" className={styles.alarm_close} />}
      maskStyle={{ background: "transparent" }}
    >
      {data?.orderId && (
        <div className={styles.warn_box}>
          {
            <div className={styles.warn_list_box}>
              <div className={styles.warn_tab_title}>历史订单</div>
              <div className={styles.warn_tale_box}>
                {loading ? (
                  <Spin></Spin>
                ) : (
                  <TopTale
                    className="inspector-rate-table"
                    dataSource={StreetParkData}
                    style={{ height: "100%" }}
                    rowKey="key"
                    columns={[
                      { title: "序号", render:(text: any,record: any,index: number)=>`${pageNum>'1'?((Number(pageNum)-1)*15)+index+1:index+1}`},
                      { title: "订单号", dataIndex: "id" },
                      { title: "驶入时间", dataIndex: "driveInTime" },
                      { title: "驶出时间", dataIndex: "driveOutTime" },
                      { title: "停车时长", dataIndex: "parkTime" },
                      { title: "车牌号", dataIndex: "plateNo" },
                      { title: "泊位号", dataIndex: "berthNo" },
                      { title: "订单来源", dataIndex: "orderSource" },
                      { title: "停车原费用", dataIndex: "fee" },
                      { title: "道路名", dataIndex: "roadName" },
                      { title: "路段名", dataIndex: "roadSectionName" },
                      { title: "实收停车费用", dataIndex: "totalFee", width: "100px" },
                      { title: "支付时间", dataIndex: "createTime" },
                    ]}
                    scroll={{ y: 500 }}
                  />
                )}
                {pagetotal > 15 && <Pagination className={styles.pagelist} defaultCurrent={Number(pageNum)} total={pagetotal} onChange={onChange} pageSize={15} />}
              </div>
            </div>
          }
        </div>
      )}

      {data?.eventManageId && (
        <div className={styles.warn_box}>
          {
            <div className={styles.warn_list_box}>
              <div className={styles.warn_tab_title}>上报事件</div>
              <div className={styles.warn_tale_box}>
                <TopTale
                  className="inspector-rate-table"
                  dataSource={workorderData}
                  style={{ height: "100%" }}
                  rowKey="key"
                  columns={[
                    { title: "序号", render: (text: any, record: any, index: number) => `${index + 1}` },
                    { title: "事件类型", dataIndex: "dictName" },
                    { title: "上报内容", dataIndex: "content" },
                    { title: "上报者", dataIndex: "reporterName" },
                    { title: "上报时间", dataIndex: "createTime" },
                    { title: "停车场/路段名称", dataIndex: "roadSectionName", width: "120px", render: (text: any, record: any) => `${record.roadSectionName}` },
                    { title: "出入口/泊位号", dataIndex: "parkingNo" },
                    { title: "车牌号", dataIndex: "plateNo" },
                    { title: "车牌颜色", dataIndex: "colorLabel" },
                    { title: "照片", dataIndex: "photoOne", render: (text: any, record: any) => <Image width={60} src={record.photoOne} /> },
                    { title: "事件处理内容", dataIndex: "handleContent" },
                    { title: "是否上报监管部门", dataIndex: "isReportLabel", width: "80px" },
                    { title: "状态", dataIndex: "statusLabel" },
                  ]}
                  scroll={{ y: 444 }}
                />
                {pagetotal > 10 && <Pagination className={styles.pagelist} defaultCurrent={Number(pageNum)} total={pagetotal} onChange={onChange} pageSize={10} />}
              </div>
            </div>
          }
        </div>
      )}

      {programType == 1 ? (
        <div className={styles.warn_box}>
          iframesrc?<iframe src={iframesrc}></iframe>:<iframe src={programUrlsdata[0]}></iframe>
        </div>
      ) : parklotphonelist.length > 0 ? (
        <div className={styles.warn_box}>
          <div className={styles.imglist}>
            <ul className={parklotphonelist.length > 6 ? styles.boxitem4 : styles.boxitem}>
              {parklotphonelist.map((item, index) => {
                return (
                  <li key={index} className={parklotphonelist.length > 6 ? styles.imgitem4 : styles.imgitem}>
                    <Image src={item["photo"]} />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : (
        programUrlsdata.length > 0 && (
          <div className={styles.imglist}>
            <ul>
              {programUrlsdata.map((item, index) => {
                return (
                  <li key={index} className={styles.imgitem}>
                    <Image src={item} />
                  </li>
                );
              })}
            </ul>
          </div>
        )
      )}

      {/* {
      programType==0&&
    <div className={styles.imglist}>
        <ul>
        {
          
        programUrlsdata.map((item,index)=>{
          return (
          <li key={index}  className={styles.imgitem}>
            <Image src={item}/>
            </li>
          )
        })}
        </ul>
      </div>
      } */}
    </Modal>
  );
});

export default AlarmModal;
