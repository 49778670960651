import React, { useImperativeHandle, forwardRef, useState, useCallback, useEffect } from "react";
import { useRequest, useInterval } from "ahooks";
import { GetRoadprogram, Getparklotprogram, GetInspectorInfont } from "@/services/api";
import { Modal } from "antd";
import styles from "./index.module.scss";
import IframeModal from "@/components/IframeModal";
import OrderlistModal from "@/components/OrderlistModal";
const InfoWindow: React.FC<{
  visible: boolean;
  onClose: () => void;
  data: {
    eventManageId?: string;
    status?: string;
    inspectorName?: "";
    inspectorCode?: "";
    deviceType?: number;
    parkId?: string;
    parkName?: string;
    roadSectionId?: string;
    roadSectionName?: string;
    areaId?: string;
    areaName?: string;
    parkingNoInfo?: string;
    trafficFlowInfo?: string;
    berthTotal?: number;
    berthEmpty?: number;
    carInTotal?: number;
    income?: number;
    carOutTotal?: number;
    deviceProducer?: string;
    unknownPlateNo?: number;
    turnover?: number;
    validTurnover?: number;
    channelInNum?: "";
    channelOutNum?: "";
    alarmInfoBean?:
      | {
          alarmInfo: string;
        }[]
      | null;
  };
  sendIframeMessage: (params: { type: string; data: any }) => void;
  onOpenWorkbill: () => void;
  onOpenMonitor: () => void;
  onOpenService: () => void;
  ref: any;
}> = forwardRef(({ visible, onClose, data, onOpenMonitor, onOpenWorkbill, onOpenService, sendIframeMessage }, ref) => {
  const berthTotal = data.berthTotal || 0;
  const berthEmpty = data.berthEmpty || 0;
  const carInTotal = data.carInTotal || 0;
  const carOutTotal = data.carOutTotal || 0;
  const roadSectionId = data.roadSectionId || "";
  const inspectorId = data.eventManageId || "";
  const parkId = data.parkId || "";
  const deviceProducer = data.deviceProducer || "";
  const deviceType = data.deviceType || 0;
  const [program, setProgram] = useState({});
  const [showiframeModal, setShowiframeModal] = useState(false);
  const [showOrderlistModal, setShowOrderlistModal] = useState(false);
  const [staffprame, setStaffprame] = useState({});
  const [workbillIframe, setWorkbillIframe] = useState(false);
  const orderdata = {
    parkId: parkId,
  };
  const [typeModal, setTypeModal] = useState("");
  const deviceTypename = (val: number) => {
    return { 0: "未知", 1: "地磁", 2: "低位", 3: "中位", 4: "高位" }[val];
  };

  const onStreetView = (val: string) => {
    //setRoad(val);
    sendIframeMessage({
      type: "focusStreet",
      data: {
        roadSectionId: val,
      },
    });
  };
  const onInspectorTrail = (val: string) => {
    //setRoad(val);
    sendIframeMessage({
      type: "showInspectorTrack",
      data: {
        inspectorId: val,
      },
    });
  };
  //路段监控
  const { data: programdata = [], run: getprogramdata } = useRequest(
    useCallback(() => {
      return GetRoadprogram({
        roadSectionId,
      });
    }, [roadSectionId]),
    {
      manual: true,
      formatResult: (res) => {
        let newdata = res.data ? res.data : {};
        setProgram(newdata);
        setTypeModal("road");
      },
    }
  );
  //巡检内容
  const { data: inspectorIdData = {}, run: getinspectorIdinfont } = useRequest(
    useCallback(() => {
      return GetInspectorInfont({
        id: inspectorId,
      });
    }, [inspectorId]),
    {
      manual: true,
      formatResult: (res) => res.data[0],
    }
  );
  const { data: parklotdata = [], run: getparklotdata } = useRequest(
    useCallback(() => {
      return Getparklotprogram({
        parkId,
      });
    }, [parkId]),
    {
      manual: true,
      formatResult: (res) => {
        let newdata = res.data ? res.data : {};
        setProgram(newdata);
        setTypeModal("parklot");
      },
    }
  );

  const openiframe = () => {
    setShowiframeModal(true);
  };
  useEffect(() => {
    inspectorId && getinspectorIdinfont();
  }, [inspectorId, getinspectorIdinfont]);
  // useImperativeHandle(ref, () => ({
  //   program
  // }))
  console.log(inspectorIdData);
  return (
    <div>
      <Modal
        className={styles.info_window}
        width={278}
        footer={null}
        visible={visible}
        onCancel={onClose}
        closeIcon={<img src="/img/icon_close_circle.png" alt="" className={styles.info_window_close} />}
        maskStyle={{ background: "transparent" }}
      >
        <div>
          <div className={styles.info_window_title}>
            {(data.parkName != undefined && data.parkName) || (data.roadSectionName != undefined && data.roadSectionName) || (data.areaName != undefined && data.areaName)}
          </div>
          {data.alarmInfoBean && data.alarmInfoBean.length > 0 && (
            <div className={styles.info_window_alarm}>
              <div className={styles.info_window_alarm_header}>
                <p>报警：{data.alarmInfoBean.length}</p>
                <button
                  onClick={() => {
                    onClose();
                    onOpenWorkbill();
                  }}
                >
                  派发工单
                </button>
              </div>
              <div className={styles.info_window_alarm_list}>
                {data.alarmInfoBean.map((item, index) => (
                  <div key={index}>
                    {index + 1}.{item.alarmInfo}
                  </div>
                ))}
              </div>
            </div>
          )}
          {data.parkId != undefined && data.parkId && (
            <div>
              <div className={styles.info_window_main}>
                <div className={styles.info_window_layout}>
                  <p className={styles.info_window_subtitle}>停车场基本情况</p>
                  <div className={styles.info_window_item}>
                    <p>位置经营： {data["address"]}</p>
                  </div>
                  <div className={styles.info_window_item}>
                    <p>管理单位： {data["manageUnit"]}</p>
                  </div>
                </div>

                <div className={styles.info_window_layout1}>
                  <p className={styles.info_window_subtitle}>业务情况</p>
                  {/* <div className={styles.info_window_item_title}>出入口数量 ：</div>
            <div className={styles.info_window_item}>
              <p>入口 ：{data.channelInNum} 辆</p>
              <p>出口 ：{data.channelOutNum} 辆</p>
            </div> */}
                  <div className={styles.info_window_item_title}>车位数 ：{berthTotal} 个</div>
                  <div className={styles.info_window_item_title}>空闲车位数：{data["status"] === "Y" ? berthEmpty + "个" : "未知"} </div>

                  <div className={styles.info_window_item_title}>停车流量 ：</div>
                  <div className={styles.info_window_item}>
                    <p>入 ：{carInTotal} 辆</p>
                    <p>出 ：{carOutTotal} 辆</p>
                  </div>
                  <div className={styles.info_window_item_title}>收入：￥{data["income"]}</div>
                  <div className={styles.info_window_item_title}>总周转率：{data["turnover"]}</div>
                  <div className={styles.info_window_item_title}>有效周转率：{data["validTurnover"]}</div>

                  <div className={styles.info_window_footer}>
                    <button
                      style={{ background: "cornflowerblue" }}
                      onClick={() => {
                        // onClose()
                        setShowOrderlistModal(true);
                      }}
                    >
                      查看订单
                    </button>
                    <button
                      onClick={() => {
                        //onClose()
                        getparklotdata();
                        openiframe();
                      }}
                    >
                      打开监控
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {roadSectionId && (
            <div>
              <div className={styles.info_window_main}>
                <div className={styles.info_window_layout}>
                  <p className={styles.info_window_subtitle}>停车场基本情况</p>
                  <div>设备类型：{deviceTypename(deviceType)}</div>

                  <div className={styles.info_window_item_title}>路段车位：</div>
                  <div className={styles.info_window_item}>
                    <p>车位：{berthTotal} 个</p>
                    <p>空：{berthEmpty} 个</p>
                  </div>

                  <div className={styles.info_window_item_title}>停车流量：</div>
                  <div className={styles.info_window_item}>
                    <p>入：{carInTotal} 辆</p>
                    <p>出：{carOutTotal} 辆</p>
                  </div>

                  <div className={styles.info_window_item_title}>未知车牌数：{data?.unknownPlateNo} 辆</div>
                </div>
                <div className={styles.info_window_layout1}>
                  <p className={styles.info_window_subtitle}>管理人员</p>
                  <div className={styles.info_window_item_title}>在岗巡检：{data.inspectorName}</div>
                  {/* <div className={styles.info_window_item}>
              <p>姓名：{data.inspectorName}</p>
              <p>工号：{data.inspectorCode}</p>
            </div> */}
                </div>

                <div className={styles.info_window_layout2}>
                  <p className={styles.info_window_subtitle}>业务情况</p>
                  <div className={styles.info_window_item_title}>收入：￥{data?.income}</div>
                  <div className={styles.info_window_item_title}>总周转率：{data?.turnover}</div>
                  <div className={styles.info_window_item_title}>有效周转率：{data?.validTurnover}</div>

                  <div className={styles.info_window_footer}>
                    <button
                      style={{ background: "cornflowerblue" }}
                      onClick={() => {
                        onClose();
                        onStreetView(roadSectionId);
                      }}
                    >
                      <img src="/img/icon_street.png" alt="" />
                      进入街景
                    </button>
                    <button
                      onClick={() => {
                        // onClose()
                        //  onOpenMonitor()
                        getprogramdata();
                        openiframe();
                      }}
                    >
                      <img src="/img/icon_eye.png" alt="" />
                      打开监控
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {inspectorId && (
            <>
              <div className={styles.info_window_main}>
                <div className={styles.info_window_layout}>
                  <p className={styles.info_window_subtitle}>基本情况</p>
                  <div className={`${styles.nfo_window_item_box} ${styles.info_window_item_title}`}>
                    姓名：{inspectorIdData.inspectorName}{" "}
                    <span
                      className={styles.inspectorTrailbtn}
                      onClick={() => {
                        onClose();
                        onInspectorTrail(inspectorId);
                      }}
                    >
                      显示轨迹
                    </span>
                  </div>
                  <div className={styles.info_window_item_title}>电话：{inspectorIdData.mobile} </div>
                  <div className={styles.info_window_item_title}>巡检组长名：{inspectorIdData.inspectorLeaderName} </div>
                  <div className={styles.info_window_item_title}>巡检组长电话：{inspectorIdData.inspectorLeaderPhone} </div>
                  <div className={styles.info_window_item_title}>工作网格：{inspectorIdData.subZoneName} </div>
                </div>

                <div className={styles.info_window_layout2} style={{ padding: "5px 20px 20px" }}>
                  <p className={styles.info_window_subtitle}>业务情况</p>
                  <div className={styles.info_window_item_title}>
                    未知订单量：{inspectorIdData.unKnownOrder}
                    <span
                      className={styles.inspectorTrailbtn}
                      onClick={() => {
                        // onClose()
                        setWorkbillIframe(true);
                        setStaffprame({ eventManageId: inspectorId });
                      }}
                    >
                      上报事件
                    </span>
                  </div>
                  <div className={styles.info_window_item_title}>当日创单量：{inspectorIdData.orderCount}</div>
                  <div className={styles.info_window_item_title}>发展用户：{inspectorIdData.addUserCount}</div>
                  <div className={styles.info_window_item_title}>追缴欠费：￥{inspectorIdData.amount}</div>
                  <div className={styles.info_window_item_title}>在岗状态：{inspectorIdData.status || Math.random() > 0.5 ? "上班" : "下班"}</div>
                </div>
              </div>
            </>
          )}
        </div>
        <IframeModal visible={workbillIframe} data={staffprame} onCancel={() => setWorkbillIframe(false)} />
        <IframeModal data={program} typeModal={typeModal} visible={showiframeModal} onCancel={() => setShowiframeModal(false)}></IframeModal>
        <OrderlistModal data={orderdata} visible={showOrderlistModal} onCancel={() => setShowOrderlistModal(false)} />
      </Modal>
    </div>
  );
});

export default InfoWindow;
