// chart数据填充
export function chartDataFill<T extends any[]>(
  list: T,
  fillProperty: string,
  fillDataProperty: string
) {
  const fillArr = [];
  console.log(list)
  // 排序的list
  const sortList = list?.sort((a, b) => a[fillProperty] - b[fillProperty]) || []
  // 填充item的函数
  const fillItemFn = (start: number, end: number) => {
    for (let i = start; i <= end; i++) {
      fillArr.push({
        [fillProperty]: i,
        [fillDataProperty]: 0,
      });
    }
  };
  // 循环排序后的list 然后根据之间的差值进行填充
  for (let i = 0; i < sortList.length - 1; i++) {
    const curItem = sortList[i];
    const nextItem = sortList[i + 1];
    const diff = nextItem[fillProperty] - curItem[fillProperty];
    fillArr.push(curItem);
    // 等于1说明是连续的
    if (diff > 1) {
      fillItemFn(curItem[fillProperty] + 1, nextItem[fillProperty] - 1);
    }
  }
  fillArr.push(sortList[sortList.length - 1]);
  return fillArr;
}
