import React, { useCallback } from 'react';
import { useRequest } from 'ahooks';
import Panel from '../index';
import { Area } from '@ant-design/charts';
import { Spin } from 'antd';
import { GetParkNervousInfo } from '@/services/api';

export interface ParkingCriticalProps {
  areaCode?: string;
}

const ParkingCritical:React.FC<ParkingCriticalProps> = ({areaCode}) => {
  const { data = [], loading, error } = useRequest(useCallback(() => {
    return GetParkNervousInfo(areaCode);
  },[areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [areaCode]
  })

  if(loading) {
    return (
      <Panel title="停车紧张区域分析">
        <div className="fetch_container" style={{ height: 270 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if(error) {
    return (
      <Panel title="停车紧张区域分析">
        <div className="fetch_container" style={{ height: 270 }}>
          请求错误
        </div>
      </Panel>
    )
  }
  return (
    <Panel
      title="停车紧张区域分析"
    >
      <Area
        height={270}
        padding={[40,7,43,48]}
        smooth
        color="#E4F6FF"
        data={data}
        xField="time"
        yField="nervousCount"
        xAxis={{
          type: 'linear',
          label: {
            style: {
              fill: '#fff'
            }
          },
          line: {
            style: {
              stroke: '#12708D'
            }
          },
          max: 24,
          tickInterval: 2,
          tickLine: null
        }}
        yAxis={{
          label: {
            style: {
              fill: '#fff'
            }
          },
          line: {
            style: {
              stroke: '#12708D'
            }
          },
          grid: {
            line: {
              style: {
                stroke: '#12708D',
                strokeOpacity: 0.4
              }
            }
          },
          minTickInterval: 50,
          min: 0
        }}
        areaStyle={{
          fill: 'l(90) 0:#2DABED 1:rgba(89, 213, 247, 0)'
        }}
        tooltip={{
          formatter: (data) => {
            return {
              name: `利用率大于85%的路段和停车场数`,
              value: `${data.nervousCount}个`
            }
          },
          showTitle: false
        }}
      />
    </Panel>
  )
}

export default ParkingCritical;