import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import Panel from '../index';
import { Liquid } from '@ant-design/charts';
import { Spin } from 'antd';
import { GetTopNervousInfo } from '@/services/api';
import styles from './index.module.scss';

export interface ParkRoadProps {
  areaCode?: string;
}

const ParkRoad:React.FC<ParkRoadProps> = ({areaCode}) => {
  const { data = [], loading, error } = useRequest(useCallback(() => {
    return GetTopNervousInfo(areaCode);
  },[areaCode]), {
    formatResult: res => res.data.splice(0,4),
    refreshDeps: [areaCode]
  })

  if(loading) {
    return (
      <Panel title="街道或停车场">
        <div className="fetch_container" style={{ height: 270 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if(error) {
    return (
      <Panel title="街道或停车场">
        <div className="fetch_container" style={{ height: 270 }}>
          请求错误
        </div>
      </Panel>
    )
  }
  return (
    <Panel
      title="街道或停车场"
    >
      <div className={styles.wave_box}>
        <div className={styles.wave_box_title}>近一小时停车数前四区域</div>
        {
          data.map((item,index) => (
            <div className={styles.wave_item} key={index}>
              <Liquid
                width={88}
                height={88}
                radius={0.97}
                percent={item.useRatio/10}
                liquidStyle={{
                  fill: 'rgba(124, 210, 255, 0.5)',
                  stroke: '#B6E6FF'
                }}
                wave={{
                  count: 2,
                  length: 30
                }}
                statistic={{
                  content: {
                    formatter: data => (data?.percent as unknown as number * 10).toFixed(1),
                    style: {
                      fontSize: '36px',
                      color: '#fff',
                      opacity: 1
                    }
                  }
                }}
              />
              <div className={styles.wave_item_label}>{item.name}</div>
            </div>
          ))
        }
      </div>
    </Panel>
  )
}

export default ParkRoad;