import { makeAutoObservable } from 'mobx'
class Usersinfo {
  token?: string 
  userId?:string 
  userName?:string
  info?: {}
  roleList?: any[] // 角色数组
  platformRoleList?: any[] // 权限数组
  userMenus?: any[] // 用户menus
  constructor() {
    makeAutoObservable(this)
  }
}

const userStore = new Usersinfo()

export default userStore
