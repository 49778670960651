import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRequest, useInterval } from 'ahooks';
import Panel from '../index';
import CustomTable from '@/components/CustomTable';
import { Spin, Button } from 'antd';
import { GetInspectorReport } from '@/services/api';
import ImgSource from '@/assets/img/jianguan/inspector.png'
export interface InspectorTableProps {
  areaCode?: string;
  sendIframeMessage: (params: {
    type: string;
    data: any;
  }) => void;
}

const $ = (selector: string) => document.querySelector(selector);

const InspectorTable:React.FC<InspectorTableProps> = props => {
  const [scrollInterval, setScrollInterval] = useState<number | null>(null);
  const [allowRefresh,setAllowRefresh] = useState(true);

  const { data = [], loading, error, refresh } = useRequest(useCallback(() => {
    return GetInspectorReport()
  }, []), {
    formatResult: res => res.data.list,
  })

  const focusInspector = (params: {
    inspectorId: string;
    lng: string;
    lat: string;
  }) => {
    props.sendIframeMessage({
      type: 'focusInspector',
      data: params
    })
  }

  const showInspectorTrack = (params: {
    inspectorId: string;
    lng: string;
    lat: string;
  }) => {
    props.sendIframeMessage({
      type: 'showInspectorTrack',
      data: params
    })
  }

  const scrollContainer = useCallback(() => {
    const container = $('.inspector-table .ant-table-body');
    if (container !== null) {
      const { clientHeight, scrollHeight, scrollTop } = container;
      let height = scrollTop + 1;
      const top = scrollHeight - clientHeight - height;
      if (top <= 0) {
        height = 0
      }

      if (scrollHeight <= clientHeight) {
        setScrollInterval(null)
      }
      
      container.scrollTo(0,height)
    }
  }, [])

  const stopTick = useCallback(() => {
    setAllowRefresh(false);
    setScrollInterval(null)
  }, [])
  
  const startTick = useCallback(() => {
    setAllowRefresh(true);
    setScrollInterval(100)
  }, [])

  useInterval(() => {
    scrollContainer()
  }, scrollInterval)

  useInterval(() => {
    refresh();
  },allowRefresh ? 60000 : null)

  useEffect(() => {
    const container = $('.inspector-table .ant-table-body')
    if (container) {
      const { clientHeight, scrollHeight } = container;
      const isOver = scrollHeight > clientHeight
      if (isOver) {
        setScrollInterval(100)
      }
    }
  }, [data])

  if (loading) {
    return (
      <Panel title="巡检人员上报事件" >
        <div className="fetch_container" style={{ height: 200 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if (error) {
    return (
      <Panel title="巡检人员上报事件">
        <div className="fetch_container" style={{ height: 200 }}>
          请求错误
        </div>
      </Panel>
    )
  }
  const dataDemo=[
    {
      inspectorId: '1',
      inspectorName: '李丽红',
      type:'违停',
      content:'事件上报具体信息',
      time:'2021-05-21'
    },
    {
      inspectorId: '2',
      inspectorName: '李丽红',
      type:'套牌车',
      content:'事件上报具体信息',
      time:'2021-05-25'
    },
    {
      inspectorId: '3',
      inspectorName: '李丽红',
      type:'设备故障',
      content:'事件上报具体信息',
      time:'2021-05-26'
    },
    {
      inspectorId: '4',
      inspectorName: '李丽红',
      type:'车辆拥堵',
      content:'事件上报具体信息',
      time:'2021-05-30'
    },
    {
      inspectorId: '5',
      inspectorName: '李丽红',
      type:'不规范停车',
      content:'事件上报具体信息',
      time:'2021-05-31'
    },
    {
      inspectorId: '6',
      inspectorName: '李丽红',
      type:'缴费排队',
      content:'事件上报具体信息',
      time:'2021-06-01'
    },
   
  ];
  return (
    <Panel
      title="巡检人员上报事件" 
    >
      <div onMouseEnter={stopTick} onMouseLeave={startTick}>
    
        <CustomTable
          className="inspector-table"
          dataSource={data}        
          rowKey={'index'}
          style={{height: '196px'}}
          columns={[
            { title: '巡检员', dataIndex: 'reporterName' },
            { title: '事件类型',dataIndex: 'dictName' },
            { title: '事件内容',dataIndex: 'content' },
            { title: '上报时间',dataIndex: 'createTime' }
            /*{ title: '定位', render: item => (
              <Button type="text" onClick={() => focusInspector(item)}><img src="/img/icon_man.png" alt=""/></Button>
            ) },
            { title: '轨迹', render: item => (
              <Button type="text" onClick={() => showInspectorTrack(item)}><img src="/img/icon_pin.png" alt=""/></Button>
            ) },
            { title: '新发展用户', dataIndex: 'content' }*/
          ]}
           scroll={{y: 156}}
        />
      </div>
    </Panel>
  )
}

export default InspectorTable;