import React, { useCallback, useState } from 'react';
import { useRequest } from 'ahooks';
import Panel from '../index';
import Balls from '@/components/Balls';
import { Line } from '@ant-design/charts';
import { Spin } from 'antd';
import { GetGridFocusInfo, GetGridFocusLineInfo } from '@/services/api';

export interface ParkingDesityProps {
  areaCode?: string;
}

const ParkingDensity:React.FC<ParkingDesityProps> = ({areaCode}) => {
  const [zoneId,setZoneId] = useState('');

  const { data: focusData = [], loading: focusLoading, error: focusError } = useRequest(useCallback(() => {
    return GetGridFocusInfo(areaCode);
  },[areaCode]), {
    formatResult: res => res.data.zoneFocusRatioInfo.map(item => ({
      label: item.zoneName,
      value: item.zoneAvgFocusRatio,
      id: item.zoneId
    })),
    onSuccess: (data: { id: string }[]) => {
      setZoneId(data[0].id)
    },
    refreshDeps: [areaCode]
  })

  const { data: gridLineData = [], loading: lineLoading, error: lineError } = useRequest(useCallback(() => {
    return GetGridFocusLineInfo({
      areaCode,
      zoneId
    })
  },[areaCode,zoneId]), {
    formatResult: res => res.data.map(series => series.dateFocusRatio.map(item => ({
      typeName: series.type === 1 ? '当月' : '上月',
      date: item.date,
      value: Math.round(item.focusRatio * 100)
    }))).flat(),
    ready: zoneId !== '',
    refreshDeps: [areaCode,zoneId]
  })

  if(focusLoading) {
    return (
      <Panel title="停车集中指数">
        <div className="fetch_container" style={{ height: 200 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if(focusError || lineError) {
    return (
      <Panel title="停车集中指数">
        <div className="fetch_container" style={{ height:200 }}>
          请求错误
        </div>
      </Panel>
    )
  }

  return (
    <Panel
      title="停车集中指数"
    >
      <Balls
        data={focusData}
        onChange={val => setZoneId(val)}
      />
      {
        lineLoading ?
        <div className="fetch_container" style={{ height: 200 }}>
          <Spin/>
        </div> :
        <div style={{ height: 250 }}>
          <Line
            height={200}
            padding={[20,14,28,12]}
            smooth
            color={['#2DABED','#FFC045']}
            data={gridLineData}
            seriesField="typeName"
            xField="date"
            yField="value"
            xAxis={{
              type: 'linear',
              label: {
                style: {
                  fill: '#fff'
                }
              },
              line: {
                style: {
                  stroke: '#12708D'
                }
              },
              minLimit: 1,
              maxLimit: 31,
              tickCount: 16,
              tickLine: null
            }}
            yAxis={{
              label: {
                formatter: v => `${v}%`,
                style: {
                  fill: '#fff'
                }
              },
              line: {
                style: {
                  stroke: '#12708D'
                }
              },
              grid: {
                line: {
                  style: {
                    stroke: '#12708D',
                    strokeOpacity: 0.4
                  }
                }
              },
              max: 100,
              min: 0
            }}
            legend={{
              position: 'top',
              itemName: {
                style: {
                  fill: '#fff'
                }
              },
            }}
            tooltip={{
              formatter: (data) => {
                return {
                  name: `${data.typeName}${data.date}日集中率`,
                  value: `${data.value}%`
                }
              },
              showTitle: false
            }}
          />
        </div>
      }
    </Panel>
  )
}

export default ParkingDensity;