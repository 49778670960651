import React, { ReactNode } from 'react';
import { Modal as Dialog } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import styles from './index.module.scss';

interface AlarmModalProps extends ModalProps {
  size?: 'sm' | 'lg' |'bg'//default sm
}

const Modal:React.FC<AlarmModalProps> = (props) => {
  const { size = 'sm' } = props;
  return (
    <Dialog
      {...props}
      className={`${styles.alarm_modal} ${size === 'lg' ? styles.alarm_modal_lg :size === 'bg' ?styles.alarm_modal_bg : ''}`}
      width={size === 'lg' ? 700 :size === 'bg' ? 800: 450}
      footer={null}
      closeIcon={<img src="/img/icon_close.png" alt="" className={styles.alarm_close} />}
      maskStyle={{background: 'transparent'}}
    >
      {props.children}
    </Dialog>
  )
}

export default Modal;